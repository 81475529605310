import { Box, Center, Image, Divider } from "@chakra-ui/react";
import React from "react";
import { COLORS_PRIMARY } from "../shared/opalite_colors";
import TempWalletIcon from "../../images/temp_wallet_icon.svg";
import { OpaliteTextStandard } from "../shared/opalite_text";

interface HowToWalletProps {}

const HowToWallet: React.FC<HowToWalletProps> = (props) => {
  return (
    <Center
      padding="2rem"
      height="100vh"
      backgroundColor={COLORS_PRIMARY.BACKGROUND}
    >
      <Box width="100%" backgroundColor={COLORS_PRIMARY.BACKGROUND_OPAQUE}>
        <Box
          borderRadius="10px"
          borderColor="white"
          borderWidth="1px"
          padding="1rem"
          display="flex"
          flexDirection="column"
        >
          <Box
            alignItems="end"
            marginBottom="1rem"
            display="flex"
            flexDirection="row"
          >
            <Image marginRight="1rem" width="1.5rem" src={TempWalletIcon} />{" "}
            <OpaliteTextStandard>Phantom</OpaliteTextStandard>
          </Box>
          <Divider
            marginTop="-.5rem"
            marginBottom="1rem"
            color="white"
            height="2px"
          />
          <Box
            alignItems="end"
            marginBottom="1rem"
            display="flex"
            flexDirection="row"
          >
            <Image marginRight="1rem" width="1.5rem" src={TempWalletIcon} />{" "}
            <OpaliteTextStandard>Solflare</OpaliteTextStandard>
          </Box>
          <Divider
            marginTop="-.5rem"
            marginBottom="1rem"
            color="white"
            height="2px"
          />
          <Box
            alignItems="end"
            marginBottom="1rem"
            display="flex"
            flexDirection="row"
          >
            <Image marginRight="1rem" width="1.5rem" src={TempWalletIcon} />{" "}
            <OpaliteTextStandard>Torus</OpaliteTextStandard>
          </Box>
          <Divider
            marginTop="-.5rem"
            marginBottom="1rem"
            color="white"
            height="2px"
          />
          <Box
            alignItems="end"
            marginBottom="1rem"
            display="flex"
            flexDirection="row"
          >
            <Image marginRight="1rem" width="1.5rem" src={TempWalletIcon} />{" "}
            <OpaliteTextStandard>Ledger</OpaliteTextStandard>
          </Box>
          <Divider
            marginTop="-.5rem"
            marginBottom="1rem"
            color="white"
            height="2px"
          />
          <Box
            alignItems="end"
            marginBottom="1rem"
            display="flex"
            flexDirection="row"
          >
            <Image marginRight="1rem" width="1.5rem" src={TempWalletIcon} />{" "}
            <OpaliteTextStandard>Sollet</OpaliteTextStandard>
          </Box>
          <Divider
            marginTop="-.5rem"
            marginBottom="1rem"
            color="white"
            height="2px"
          />
          <Box
            alignItems="end"
            marginBottom="1rem"
            display="flex"
            flexDirection="row"
          >
            <Image marginRight="1rem" width="1.5rem" src={TempWalletIcon} />{" "}
            <OpaliteTextStandard>Slope</OpaliteTextStandard>
          </Box>
          <Divider
            marginTop="-.5rem"
            marginBottom="1rem"
            color="white"
            height="2px"
          />
          <Box alignItems="end" display="flex" flexDirection="row">
            <Image marginRight="1rem" width="1.5rem" src={TempWalletIcon} />{" "}
            <OpaliteTextStandard>Sollet (Extension)</OpaliteTextStandard>
          </Box>
        </Box>
      </Box>
    </Center>
  );
};

export default HowToWallet;
