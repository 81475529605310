import * as React from "react";
import { Box } from "@chakra-ui/react";
import Header from "../components/header/header";
import HowToWallet from "../components/how_to_wallet/how_to_wallet";

const HowToWalletPage = () => {
  return (
    <Box>
      <Header />
      <HowToWallet />
    </Box>
  );
};

export default HowToWalletPage;
